import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useAppInterfaceStore = defineStore('appInterface', () => {
  const quitAssessment = ref(false); // 두뇌 검사 페이지 이탈 여부
  const resetNotificationPermission = ref(false); // 알림 설정 변경 실패 판별 -> 알림 여부 초기화
  const activeNotificationAlert = ref(false); // 알림 허용 안내 팝업
  const handleRequestMicCallback = ref(false); // requestRequestMicCallback interaction 동작 처리
  const requestMicCallbackValue = ref(false); // requestRequestMicCallback interaction 값 처리

  const getQuitAssessment = computed(() => quitAssessment.value);
  const getResetNotificationPermission = computed(
    () => resetNotificationPermission.value
  );
  const getActiveNotificationAlert = computed(
    () => activeNotificationAlert.value
  );
  const getHandleRequestMicCallback = computed(
    () => handleRequestMicCallback.value
  );
  const getRequestMicCallbackValue = computed(
    () => requestMicCallbackValue.value
  );

  function setQuitAssessment(quitFlag: boolean) {
    quitAssessment.value = quitFlag;
  }
  function setResetNotificationPermission(quitFlag: boolean) {
    resetNotificationPermission.value = quitFlag;
  }
  function setActiveNotificationAlert(quitFlag: boolean) {
    activeNotificationAlert.value = quitFlag;
  }
  function setHandleRequestMicCallback(quitFlag: boolean) {
    handleRequestMicCallback.value = quitFlag;
  }
  function setRequestMicCallbackValue(quitFlag: boolean) {
    requestMicCallbackValue.value = quitFlag;
  }

  return {
    getQuitAssessment,
    getResetNotificationPermission,
    getActiveNotificationAlert,
    getHandleRequestMicCallback,
    getRequestMicCallbackValue,
    setQuitAssessment,
    setResetNotificationPermission,
    setActiveNotificationAlert,
    setHandleRequestMicCallback,
    setRequestMicCallbackValue,
  };
});
