import { defineStore } from 'pinia';
import { computed, Ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

export type TSetting = {
  locale: string;
};

export const useSettingStore = defineStore('setting', () => {
  const setting: Ref<TSetting> = useLocalStorage(
    `alzguard_${process.env.VUE_APP_ENV}/setting`,
    {
      locale: useI18n().locale.value,
    } as TSetting
  );

  const getSetting = computed(() => {
    return setting.value;
  });

  function setSetting(newSetting: Partial<TSetting>) {
    setting.value = { ...setting.value, ...newSetting };
  }

  return {
    setting,
    getSetting,
    setSetting,
  };
});
