export const $TrainingInfo = {
  trainingTitle: '두뇌훈련',
  limitTime: '제한 시간',
  timeUnit: '분',
  totalCount: '총',
  countUnit: '문제',
  startTraining: '시작하기',
};

export const $ExitNotice = {
  exitNoticeTitle: '게임 도중 이탈시 지금까지\n진행하신 이력이 사라집니다.',
  exitNoticeComment:
    '게임 진행 도중 이탈을 하시면 진행\n중이셨던 데이터를 저장할 수 없습니다.',
  exitNoticeFirstButton: '나가기',
  exitNoticeSecondButton: '돌아가기',
};

export const $InputWindowPlaceHolder = {
  objectivePlaceHolder: '위 보기 중 하나를 골라주세요',
  subjectivePlaceHolder: '답변을 이곳에 적어 보내주세요',
};

export const $SemiName = {
  semi: '새미',
};

export const $GameResult = {
  resultTitle: '오늘의 결과',
  countUnit: '개',
  totalTrainingCount: '총 푼 문제 수',
  correctTrainingCount: '맞은 문제 수',
  share: '친구와 공유하기',
};
