import { useI18n } from 'vue-i18n';
import { ko } from 'src/i18n/ko/ko';
import useUtil from 'src/composables/useUtil';

// 컴포넌트 이름을 가져오려면 현재 인스턴스에 접근하여 가져와야함

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

export type typeKo = keyof UnionToIntersection<(typeof ko)[keyof typeof ko]>;

type translateOptionType = {
  componentName?: string;
  msg?: string;
};

export default function useLang() {
  const i18n = useI18n();
  const { getComponentName } = useUtil();
  const instanceComponentName = getComponentName();

  const translate = (
    keyword: typeKo | string,
    translateOption?: translateOptionType
  ) => {
    return i18n.t(
      `${translateOption?.componentName ?? instanceComponentName}.${keyword}`,
      { msg: translateOption?.msg }
    );
  };
  return {
    translate,
  };
}
