import { ComponentInternalInstance, getCurrentInstance } from 'vue';

export default function useUtil() {
  const delay = (num?: number) =>
    new Promise<void>((resolve) => setTimeout(() => resolve(), num));

  const getComponentName = (): string | undefined => {
    const internalInstance: ComponentInternalInstance | null =
      getCurrentInstance();
    if (!internalInstance) return;
    return internalInstance.type.__name;
  };

  function getRandomNumber(min: number, max: number) {
    // min <= random < max 난수 생성기
    min = Math.ceil(min);
    max = Math.floor(max);
    const range = max - min;
    const bytesNeeded = Math.ceil(Math.log2(range) / 8);
    const mask = Math.pow(2, Math.ceil(Math.log2(range))) - 1;
    const byteArray = new Uint8Array(bytesNeeded);
    let num;

    do {
      window.crypto.getRandomValues(byteArray);
      num = 0;
      for (let i = 0; i < bytesNeeded; i++) {
        num = (num << 8) | byteArray[i];
      }
      num = num & mask;
    } while (num >= range);

    return num + min;
  }

  return { delay, getComponentName, getRandomNumber };
}
