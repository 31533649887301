export type tokenType = {
  AccessToken: string;
  AccessTokenExpires: string;
  RefreshToken: string;
  RefreshTokenExpires: string;
};

export const tokenResponseType = {
  success: 'success',
  refresh: 'refresh',
  expired: 'expired',
};

export const appType = {
  aos: 'aos',
  ios: 'ios',
};
