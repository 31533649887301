export const $Onboarding = {
  onboardingTitle1: 'First, measure your \n current cognitive skills.',
  onboardingTitle2:
    'Receive personalized \n training per your \n cognitive skills.',
  onboardingTitle3:
    'See your cognitive \n skills evolve from \n personalized training.',
  onboardingTitle4: '',
  nextButton: 'Next',
  startButton: 'Start',
};

export const $BottomNavigation = {
  home: 'Home',
  training: 'Training',
  setting: 'Setting',
};

export const $BeforeAssessment = {
  beforeAssessmentTitle: 'Complete the\ncognitive assessment.',
  beforeAssessmentSubtitle:
    'After the assessment, you can \n receive personalized training \n based on your current \n cognitive skills.',
  beforeAssessmentButton: 'Start cognitive assessment',
};

export const $Reassessment = {
  reassessmentTitle: 'Today is the day\nfor the re-test!\nShall we start now?',
  reassessmentSubtitle:
    'After testing, you can check your\nupdated cognitive skills and\nreceive personalized training 😊',
  reassessmentButton: 'Start test',
};

export const $HomeHeader = {
  assessmentResult: '',
  firstAssessment:
    'You scored an average of _avgScore% on your first cognitive assessment.',
  compareChanged: 'Since last assessment',
  compareUnchanged: 'Since last assessment',
  unchanged: 'Unchanged',
  increase: 'Increased _diff%',
  decrease: 'Dropped _diff%',
  leftDays: 'DAYS LEFT',
};

export const $HomeScore = {
  title: 'My Cognitive Skills',
  increaseComment: 'Increased _scoreDiff% since last time',
  decreaseComment: 'Decreased _scoreDiff% since last time',
  unchangedComment: 'Same as last time',
  lowScoreComment: 'Cognitive impairment is suspected.',
  Memory: 'Memory',
  ExecutiveFunction: 'Executive Function',
  Calculation: 'Calculation',
};

export const $PersonalTrainingInfo = {
  personalTrainingTitle: 'Personalized Training',
  personalTrainingSubtitle:
    'Personalized training is provided based on the assessment results',
  personalTrainingExplain:
    'Artificial intelligence anlyzes the assessment results and recommends games that take approximately 15 minutes. Cognitive skills are strengthened when you complete the games everyday for 30 days. After 30 days, you can re-assess to see changes in your cognitive skills and receive further optimized personalized training.',
};

export const $CognitiveSkillInfo = {
  cognitiveSkillTitle: 'Cognitive Skill',
  cognitiveSkillSubtitle:
    'Memory, executive function, and calculation are cognitive skills closely linked to dementia',
  memoryTitle: 'Memory',
  memoryExplain:
    'Memory is made through a series of processes in which acquired information is encoded, stored, and withdrawn. If one of these three processes has a problem, it will cause a memory defect. If memory is impaired, memory loss is setting pronounced than general forgetfulness, such as forgetting medication times or important appointments.',
  executiveTitle: 'Executive Function',
  executiveExplain:
    'Executive function is the ability to solve problems by controlling impulsivity, making plans, and organizing given information. The decline in executive function makes it difficult to operate complex tools and handle a series of continuous tasks such as banking, making it difficult to live independently.',
  calculationTitle: 'Calculation',
  calculationExplain:
    'Calculation includes the ability to understand logic and rules as well as the ability to calculate simple rules. The decline in calculation is directly related to the difficulty of calculating money. You may experience inconvenience due to repeated mistakes in calculating the change and not being able to properly calculate the value of the item you want to buy when shopping.',
};

export const $HomeTrainingButton = {
  title: 'Personalized Training',
};

export const $TrainingButton = {
  trainingButton: 'Start Training',
  finishedTrainingButton: 'Training Complete',
};

export const $AssessmentGuideDialog = {
  title: 'Complete \n assessment first to \n access this tab.',
  comment: '',
  firstButton: 'Close',
  secondButton: 'Start',
};

export const $TrainingTitle = {
  trainingTitle: 'Training',
  customTrainingTitle: 'Personalized',
  allTrainingTitle: 'See All',
};

export const $SettingTitle = {
  settingTitle: 'Setting',
};

export const $SettingUserAttendance = {
  attendance: 'Checked in _day consecutive days',
};

export const $SettingMenu = {
  alertTitle: '',
  alertPermission: '',
  serviceTitle: 'Service',
  langSetting: 'Language',
  inquiry: 'Submit Feedback',
  appVersion: '',
  policyTitle: 'Terms and Conditions',
  serviceTerms: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  accountTitle: 'Accounts',
  logout: 'Log Out',
  withdraw: 'Delete Account',
};

export const $Withdraw = {
  withdrawTitle: 'Delete Account',
  withdrawReasonTitle: 'May we ask why you are\nleaving us?',
  withdrawReasonUnused: 'Infrequent use',
  withdrawReasonDifficult: 'Difficult to use',
  withdrawReasonNewAccount: 'I want to create new account',
  withdrawReasonPolicy: 'Personal information protection',
  withdrawReasonAnotherAccount: 'I have another account',
  withdrawReasonEtc: 'Etc.',
  decideWithdraw: 'Delete',
  cancelWithdraw: 'Cancel',
};

export const $CustomerCenter = {
  customerCenterTitle: '',
  customerCenterComment: '',
  customerCenterTime: '',
};

export const $AppVersion = {
  appVersionTitle: '',
  currentVersion: '',
  latestVersion: '',
};

export const $LogoutDialog = {
  logoutTitle: '',
  logoutFirstButton: '',
  logoutSecondButton: '',
};
