export const $Onboarding = {
  onboardingTitle1: '먼저, 두뇌검사를\n진행해 주세요.',
  onboardingTitle2: '나에게 필요한\n두뇌훈련을\n추천해 드릴게요.',
  onboardingTitle3: '두뇌훈련 후,\n향상되는 두뇌능력을\n지켜보아요.',
  onboardingTitle4: '새미가\n두뇌 훈련을\n도와드릴게요.',
  nextButton: '다음으로',
  startButton: '시작하기',
};

export const $BottomNavigation = {
  home: '두뇌검사',
  training: '두뇌훈련',
  setting: '설정',
};

export const $BeforeAssessment = {
  beforeAssessmentTitle: '두뇌능력을\n측정해볼까요?',
  beforeAssessmentSubtitle:
    '검사 후, 내 두뇌능력을 향상시키기 위한 훈련을 받을 수 있어요!',
  beforeAssessmentButton: '두뇌능력 측정하기',
};

export const $Reassessment = {
  reassessmentTitle: '나의 두뇌능력이\n어떻게 달라졌을까?',
  reassessmentSubtitle:
    '재검사 후, 달라진 내 두뇌능력에 맞춘 훈련을 할 수 있어요!',
  reassessmentButton: '재검사 하러가기',
};

export const $HomeHeader = {
  assessmentResult: '_userName 님의\n두뇌능력 점수',
  firstAssessment: '1회차 검사 결과',
  compareChanged: '지난 검사보다',
  compareUnchanged: '지난 검사와',
  unchanged: '같음',
  increase: '_diff점 상승',
  decrease: '_diff점 하락',
  leftDays: '일 남음',
};

export const $HomeScore = {
  title: '내 두뇌능력 상태',
  increaseComment: '지난 검사보다 _scoreDiff점 상승했어요',
  decreaseComment: '지난 검사보다 _scoreDiff점 하락했어요',
  unchangedComment: '지난 검사와 동일해요',
  lowScoreComment: '우리 같이 더 열심히 훈련해 보아요.',
  Memory: '기억력',
  ExecutiveFunction: '판단력',
  Calculation: '계산력',
};

export const $PersonalTrainingInfo = {
  personalTrainingTitle: '맞춤형 두뇌훈련이란?',
  personalTrainingSubtitle:
    '나의 검사 결과를 기반으로 맞춤 게임을 추천해 드려요',
  personalTrainingExplain:
    '검사 결과를 인공지능이 분석하여 하루 15분 정도 소요되는 게임을 제공해 줍니다. 게임을 꾸준히 매일 30일 동안 하면 두뇌능력이 향상됩니다.\n\n30일 후 검사를 다시 하셔서 변화된 나의 두뇌 능력을 확인하시고, 다시 나에게 최적화된 두뇌훈련을 추천받아 보세요.',
};

export const $CognitiveSkillInfo = {
  cognitiveSkillTitle: '두뇌기능 정보',
  cognitiveSkillSubtitle:
    '기억력, 판단력, 계산력은\n치매와 밀접한 관련이 있는\n두뇌능력이에요',
  memoryTitle: '기억력',
  memoryExplain:
    '기억은 획득한 정보가 등록되고 저장되며 인출되는 일련의 과정을 통해 이루어져요. 이 세 과정 중 한 가지라도 문제가 생길경우 기억력에 결함이 발생하게 돼요. 기억력이 저하될 경우 복약 시간이나 중요한 약속을 잊어버리는 등 일반적인 건망증보다 두드러진 기억력 저하의 양상이 나타나요.',
  executiveTitle: '판단력',
  executiveExplain:
    '판단력은 충동성을 조절하고 계획을 세우며 주어진 정보를 조직화하여 문제를 해결하는 능력이에요. 판단력의 저하는 복잡한 도구의 조작과 은행 업무와 같은 일련의 연속된 업무 처리를 어렵게 하여 독립적인 생활을 힘들게 해요.',
  calculationTitle: '계산력',
  calculationExplain:
    '계산력은 단순한 사칙연산 능력 뿐만 아니라 논리와 규칙을 이해하는 능력을 포함해요. 계산력의 저하는 돈 계산의 어려움과 직결돼요. 거스름돈 계산을 반복적으로 실수하게되고 장을 볼 때도 구매하고자 하는 물건의 값을 적절히 계산하지 못해 불편함을 겪을 수 있어요.',
};

export const $HomeTrainingButton = {
  title: '나를 위한 맞춤 두뇌훈련',
};

export const $TrainingButton = {
  trainingButton: '맞춤형 두뇌훈련하기',
  finishedTrainingButton: '오늘의 훈련을 완료했어요!',
};

export const $AssessmentGuideDialog = {
  title: '두뇌능력 측정 후,\n이용이 가능합니다.',
  comment: '두뇌능력 상태부터\n알아보러 갈까요?',
  firstButton: '닫기',
  secondButton: '측정하기',
};

export const $TrainingTitle = {
  trainingTitle: '두뇌훈련',
  customTrainingTitle: '맞춤형 훈련',
  allTrainingTitle: '전체 훈련',
};

export const $SettingTitle = {
  settingTitle: '설정',
};

export const $SettingUserAttendance = {
  attendance: '연속 _day일 출석 중',
};

export const $SettingMenu = {
  alertTitle: '알림',
  alertPermission: '알림 허용',
  serviceTitle: '서비스',
  langSetting: '언어 설정',
  inquiry: '고객센터',
  appVersion: '앱 정보',
  policyTitle: '약관 및 정책',
  serviceTerms: '서비스 이용약관',
  privacyPolicy: '개인정보 처리 방침',
  accountTitle: '계정관리',
  logout: '로그아웃',
  withdraw: '회원 탈퇴',
};

export const $Withdraw = {
  withdrawTitle: '회원탈퇴',
  withdrawReasonTitle: '새미랑을 떠나시는 이유가\n있을까요?',
  withdrawReasonUnused: '사용을 잘 안 하게 돼요',
  withdrawReasonDifficult: '서비스 사용이 어려워요',
  withdrawReasonNewAccount: '새 계정을 만들고 싶어요',
  withdrawReasonPolicy: '개인정보 보호를 위해\n삭제할 정보가 있어요',
  withdrawReasonAnotherAccount: '다른 계정이 있어요',
  withdrawReasonEtc: '기타',
  decideWithdraw: '떠날게요',
  cancelWithdraw: '더 써볼래요',
};

export const $CustomerCenter = {
  customerCenterTitle: '고객센터',
  customerCenterComment: '도움이 필요하신가요?',
  customerCenterTime: '평일 10:00 ~ 17:00 (주말, 공휴일 제외)',
};

export const $AppVersion = {
  appVersionTitle: '앱 정보',
  currentVersion: '현재 버전',
  latestVersion: '최신 버전',
};

export const $LogoutDialog = {
  logoutTitle: '로그아웃\n하시겠습니까?',
  logoutFirstButton: '취소',
  logoutSecondButton: '로그아웃',
};
