export const $NotFound = {
  notFoundTitle: '잘못된 접근 페이지 입니다.',
  notFoundComment: '입력하신 주소를\n다시 확인해주시기 바랍니다.',
  notFoundButton: '홈으로 가기',
};

export const $ErrorBottomDialog = {
  errorBottomDialogTitle: '죄송합니다.\n서버가 불안정합니다.',
  errorBottomDialogComment: '잠시 후 다시 돌아와 주시기 바랍니다.',
  errorBottomDialogButton: '새로고침',
};

export const $SignInErrorDialog = {
  signInErrorDialogTitle: '로그인에 실패하였습니다.',
  signInErrorDialogComment: '다시 시도해주시기 바랍니다.',
  signInErrorDialogButton: '다시 로그인하기',
};

export const $TrainingCategories = {
  Memory: '기억력',
  ExecutiveFunction: '판단력',
  Calculation: '계산력',
  Language: '언어능력',
  Attention: '주의력',
  VisuoSpatial: '시공간능력',
};

export const $TrainingGameTitle = {
  '000': '맞춤형 두뇌훈련',
  101: '기초부터 차근차근',
  102: '네모의 수를 맞혀주세요',
  103: '암호를 풀어보세요',
  104: '식사합시다',
  201: '단어찾기',
  202: '같은그림 찾기',
  301: '시 외우기',
  302: '만국기 게임',
  303: '사자성어',
  304: '과일아 어디있니?',
  401: '첫 글자를 맞혀라',
  402: '단어 재배열',
  501: '여행 계획은 즐거워',
  502: '순서맞추기',
  601: '무엇이 다를까?',
  9101: '물건금액 계산하기',
  9201: '짐을 챙겨라',
  9202: '오늘의 기념품은?',
  9203: '숙소 입실',
  9301: '어디서 뭐 먹지',
  9302: '경로 찾기',
  9303: '여행 짐아 어디있니?',
  9401: '단어 재배열',
  9601: '캐리어 회전',
  9602: '어디서 찍은 사진일까요',
};
