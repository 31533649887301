import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
export const useUtilStore = defineStore('util', () => {
  const isLoading = ref(false);
  const isAnalyzing = ref(false);
  const isErrorBottom = ref(false);
  const isErrorCenter = ref(false);

  const getLoading = computed(() => isLoading.value);
  const getAnalyzing = computed(() => isAnalyzing.value);
  const getErrorBottom = computed(() => isErrorBottom.value);
  const getErrorCenter = computed(() => isErrorCenter.value);

  function setLoading(loadingValue: boolean) {
    isLoading.value = loadingValue;
  }

  function setAnalyzing(analyzingValue: boolean) {
    isAnalyzing.value = analyzingValue;
  }

  function setErrorBottom(errorValue: boolean) {
    isErrorBottom.value = errorValue;
  }

  function setErrorCenter(errorValue: boolean) {
    isErrorCenter.value = errorValue;
  }

  return {
    getLoading,
    getAnalyzing,
    getErrorBottom,
    getErrorCenter,
    setLoading,
    setAnalyzing,
    setErrorBottom,
    setErrorCenter,
  };
});
