export const $ButtonText = { proceed: 'Proceed', next: 'Next' };

export const $Agreement = {
  agreement1: 'By signing up, you agree to the ',
  agreement2: 'Terms of Service',
  agreement3: ' and ',
  agreement4: 'Privacy Policy',
  agreement5: '.',
};
export const $ScreenRotateGuide = {
  screenRotateGuide: 'Please rotate\nyour screen for the\nassessment.',
};

export const $ScreenFixGuideDialog = {
  fixTitle: 'Please fix your screen orientation \n throughout the assessment.',
  fixSubTitle: 'Rotating your screen may lead to loss of your progress.',
};

export const $AssessmentExitDialog = {
  exitDialogTitle: '',
  exitDialogUserInfo: '',
  exitDialogAssessment: '',
  exitDialogYes: '',
  exitDialogNo: '',
};

export const $UserInfoAgreements = {
  agreementsTitle: '',
  agreementsAllAgree: '',
  agreementsPp: '',
  agreementsSpp: '',
  agreementsEula: '',
  agreementsTp: '',
};

export const $UserInfoCode = {
  codeTitle: 'Please enter the code given by the administrator',
  codePlaceholder: 'Enter Code',
  codeError: 'Invalid access code',
};

export const $UserInfoName = {
  nameTitle: 'Enter your name',
  namePlaceholder: 'Name',
};

export const $UserInfoPhone = {
  phoneTitle: 'Enter your phone number (exclude "-")',
  phonePlaceholder: 'Phone number',
};

export const $UserInfoBirth = {
  birthTitle: 'Select your date of birth',
  birthYear: 'Year',
  birthMonth: 'Month',
  birthDate: 'Date',
};

export const $UserInfoGender = {
  genderTitle: 'Select your gender',
  female: 'female',
  male: 'male',
  other: 'other',
};

export const $UserInfoEduction = {
  educationTitle: 'Select your educational background',
};

export const $UserInfoFinish = {
  userInfoFinishTitle: 'Well done!',
  userInfoFinishComment: "Let's move on to the test.",
};

export const $NumericInfo = {
  numericInfoTitle: 'Numeric Stroop Test',
  numericInfoComment:
    'Look at the number shown on the left, then select the digit on the right.',
};

export const $NumericPracticeCorrect = {
  numericPracticeCorrectTitle: '',
  numericPracticeCorrectComment: '',
};

export const $NumericPracticeWrong = {
  numericPracticeWrongTitle: '',
  numericPracticeWrongComment: '',
};

export const $NumericHeaderTitle = {
  numericHeaderTitle: '',
};

export const $NumericFinish = {
  numericFinishTitle: '',
  numericFinishComment: '',
};

export const $StroopInfo = {
  stroopInfoTitle: '',
  stroopInfoComment: '',
};

export const $StroopPracticeCorrect = {
  stroopPracticeCorrectTitle: '',
  stroopPracticeCorrectComment: '',
};

export const $StroopPracticeWrong = {
  stroopPracticeWrongTitle: '',
  stroopPracticeWrongComment: '',
};

export const $StroopHeaderTitle = {
  stroopHeaderTitle: '',
};

export const $StroopFinish = {
  stroopFinishTitle: '',
  stroopFinishComment: '',
};

export const $RememberInfo = {
  rememberInfoTitle: '',
  rememberInfoComment: '',
};

export const $RememberHeaderTitle = {
  rememberQuestionHeaderTitle: '',
  rememberAnswerHeaderTitle: '',
};

export const $RememberExample = {
  rememberExampleTitle: '',
  rememberExampleComment: '',
};

export const $RememberPracticeCorrect = {
  rememberPracticeCorrectTitle: '',
  rememberPracticeCorrectComment: '',
};

export const $RememberPracticeWrong = {
  rememberPracticeWrongTitle: '',
  rememberPracticeWrongComment: '',
};

export const $RememberCountdown = {
  rememberCountdown: '',
};

export const $RememberTestExample = {
  rememberTestExampleTitle: '',
  rememberTestExampleComment: '',
};

export const $RememberFinish = {
  rememberFinishTitle: '',
  rememberFinishComment: '',
};

export const $MemorizeInfo = {
  memorizeInfoTitle: '',
  memorizeInfoComment: '',
};

export const $MemorizeFinish = {
  memorizeFinishTitle: '',
  memorizeFinishComment: '',
};

export const $MemorizeTitle = {
  memorizeSentenceQuestionTitle: '',
  memorizeSentenceAnswerTitle: '',
};

export const $ChooseBiggerTitle = {
  chooseBiggerTitle: '',
};

export const $ChooseBigger = {
  chooseBiggerSame: '',
};

export const $MemorizeSentence = {
  memorizeSentence11: '',
  memorizeSentence12: '',
  memorizeSentence13: '',
  memorizeSentence14: '',
  memorizeSentence21: '',
  memorizeSentence22: '',
  memorizeSentence23: '',
  memorizeSentence24: '',
  memorizeSentence31: '',
  memorizeSentence32: '',
  memorizeSentence33: '',
  memorizeSentence34: '',
};

export const $TotalFinish = {
  totalFinishTitle: '',
  totalFinishComment: '',
};

export const $AssessmentInfoButton = {
  startTest: 'Next',
  startPractice: '',
  practiceAgain: '',
  start: '',
  startMemorize: '',
  continue: '',
  analyseData: '',
  totalFinish: '',
};
