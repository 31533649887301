import { defineStore } from 'pinia';
import { computed, ref, Ref } from 'vue';
import { useSessionStorage } from '@vueuse/core';
import {
  CouponResponse,
  DetailRequest,
  GameRequest,
  HistoryResponse,
  UserRequest,
} from 'src/generated/alzguardAPI';

type TAssessment = {
  audio: boolean;
  codeResponse: CouponResponse;
  userInfo: UserRequest;
  assessmentData: HistoryResponse;
  rememberNumber: number;
  isMemorizeRefresh: boolean;
  memorizeStartDT: string;
  memorizeLevel: number;
  memorizeNumber: number;
  memorizeResult: GameRequest;
  memorizeDetail: DetailRequest[];
  memorizeRecord: string[];
  memorizeRecordDetail: DetailRequest[];
  requestAnalyze: boolean;
};

type TNewData = CouponResponse | UserRequest | HistoryResponse;

export const useAssessmentStore = defineStore('assessment', () => {
  const assessment: Ref<TAssessment> = useSessionStorage(
    `alzguard_${process.env.VUE_APP_ENV}/assessment`,
    { isMemorizeRefresh: false } as TAssessment
  );

  const agreeScreenFixGuide: Ref<boolean> = useSessionStorage(
    `alzguard_${process.env.VUE_APP_ENV}/agree`,
    false
  );

  const isAnalyzeError = ref(false); // 검진 결과 요청 에러 여부
  const isSecondAnalyze = ref(false); // 두 번째 요청인지 판별
  const requestReanalyze = ref(false); // 검진 결과 재요청

  const requestMikeAccess = ref(false); // 마이크 권한 요청
  const checkMikeAccess = ref(false); // 마이크 권한 확인

  const getAssessment = computed(() => {
    return assessment.value;
  });

  const getAgreementScreenFixGuide = computed(() => agreeScreenFixGuide.value);

  const getAnalyzeError = computed(() => isAnalyzeError.value);
  const getSecondAnalyze = computed(() => isSecondAnalyze.value);
  const getRequestReanalyze = computed(() => requestReanalyze.value);

  const getMikeAccessCheck = computed(() => checkMikeAccess.value);
  const getMikeAccessRequested = computed(() => requestMikeAccess.value);

  function setAssessment(newData: TNewData, type: string) {
    switch (type) {
      case 'codeResponse':
        assessment.value.codeResponse = {
          ...assessment.value.codeResponse,
          ...newData,
        };
        break;
      case 'userInfo':
        assessment.value.userInfo = {
          ...assessment.value.userInfo,
          ...newData,
        };
        break;
      case 'assessmentData':
        assessment.value.assessmentData = {
          ...assessment.value.assessmentData,
          ...newData,
        };
        break;
      default:
        break;
    }
  }

  function setAudio(newVal: boolean) {
    assessment.value.audio = newVal;
  }

  function setRememberNum(newVal: number) {
    assessment.value.rememberNumber = newVal;
  }

  function setMemorizeRefresh(newVal: boolean) {
    assessment.value.isMemorizeRefresh = newVal;
  }

  function setMemorizeStartDT() {
    assessment.value.memorizeStartDT = String(
      Math.floor(new Date().getTime() / 1000)
    );
  }

  function setMemorizeLevel(newVal: number) {
    assessment.value.memorizeLevel = newVal;
  }

  function setMemorizeNumber(newVal: number) {
    assessment.value.memorizeNumber = newVal;
  }

  function setMemorizeResult(newVal: Partial<GameRequest>) {
    assessment.value.memorizeResult = {
      ...assessment.value.memorizeResult,
      ...newVal,
    };
  }

  function resetMemorizeDetail() {
    assessment.value.memorizeDetail = [] as DetailRequest[];
  }

  function setMemorizeDetail(newVal: DetailRequest[]) {
    assessment.value.memorizeDetail.push(...newVal);
  }

  function resetMemorizeRecord() {
    assessment.value.memorizeRecord = [] as any[];
  }

  function setMemorizeRecord(newVal: string) {
    assessment.value.memorizeRecord.push(newVal);
  }

  function popMemorizeRecord() {
    assessment.value.memorizeRecord.pop();
  }

  function resetMemorizeRecordDetail() {
    assessment.value.memorizeRecordDetail = [] as DetailRequest[];
  }
  function setMemorizeRecordDetail(newVal: DetailRequest) {
    assessment.value.memorizeRecordDetail.push(newVal);
  }
  function popMemorizeRecordDetail() {
    assessment.value.memorizeRecordDetail.pop();
  }

  function setRequestAnalyzeTrue() {
    assessment.value.requestAnalyze = true;
  }

  function setRequestAnalyzeFalse() {
    assessment.value.requestAnalyze = false;
  }

  function setAgreeScreenFixGuide(agreeValue: boolean) {
    agreeScreenFixGuide.value = agreeValue;
  }

  function setAnalyzeError(errorValue: boolean) {
    isAnalyzeError.value = errorValue;
  }

  function setSecondAnalyze(errorValue: boolean) {
    isSecondAnalyze.value = errorValue;
  }

  function setRequestReanalyze(reanalyzeValue: boolean) {
    requestReanalyze.value = reanalyzeValue;
  }

  function setMikeAccessCheck(checkFlag: boolean) {
    checkMikeAccess.value = checkFlag;
  }
  function setMikeAccessRequested(requestFlag: boolean) {
    requestMikeAccess.value = requestFlag;
  }

  return {
    assessment,
    getAssessment,
    setAudio,
    setAssessment,
    setRememberNum,
    setMemorizeRefresh,
    setMemorizeStartDT,
    setMemorizeLevel,
    setMemorizeNumber,
    setMemorizeResult,
    resetMemorizeDetail,
    setMemorizeDetail,
    resetMemorizeRecord,
    setMemorizeRecord,
    popMemorizeRecord,
    resetMemorizeRecordDetail,
    setMemorizeRecordDetail,
    popMemorizeRecordDetail,
    setRequestAnalyzeTrue,
    setRequestAnalyzeFalse,
    getAgreementScreenFixGuide,
    setAgreeScreenFixGuide,
    getAnalyzeError,
    getSecondAnalyze,
    getRequestReanalyze,
    setAnalyzeError,
    setSecondAnalyze,
    setRequestReanalyze,
    getMikeAccessCheck,
    setMikeAccessCheck,
    getMikeAccessRequested,
    setMikeAccessRequested,
  };
});
