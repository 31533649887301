export const $TrainingInfo = {
  trainingTitle: '',
  limitTime: '',
  timeUnit: '',
  totalCount: '',
  countUnit: '',
  startTraining: '',
};

export const $ExitNotice = {
  exitNoticeTitle: '',
  exitNoticeComment: '',
  exitNoticeFirstButton: '',
  exitNoticeSecondButton: '',
};

export const $InputWindowPlaceHolder = {
  objectivePlaceHolder: 'Please choose one of the options above.',
  subjectivePlaceHolder: 'Please enter your response here.',
};

export const $SemiName = {
  semi: 'Semi',
};

export const $GameResult = {
  resultTitle: '오늘의 결과',
  countUnit: '개',
  totalTrainingCount: '총 푼 문제 수',
  correctTrainingCount: '맞은 문제 수',
  share: '친구와 공유하기',
};
