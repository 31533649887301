import { defineStore } from 'pinia';
import { computed, ref, Ref } from 'vue';
import { useSessionStorage } from '@vueuse/core';
import { tokenType } from 'src/types/constType';

type TGeneral = {
  isApp: boolean;
  isDemo: boolean;
  appType: string;
  token: tokenType;
  isMainError: boolean;
};

export const useGeneralStore = defineStore('general', () => {
  const general: Ref<TGeneral> = useSessionStorage(
    `alzguard_${process.env.VUE_APP_ENV}/general`,
    { isApp: false } as TGeneral
  );

  const getIsApp = computed(() => general.value.isApp);
  function setIsApp(appFlag: boolean) {
    general.value.isApp = appFlag;
  }

  const getIsDemo = computed(() => general.value.isDemo);
  function setIsDemo(demoFlag: boolean) {
    general.value.isDemo = demoFlag;
  }

  const getAppType = computed(() => general.value.appType);
  function setAppType(appType: string) {
    general.value.appType = appType;
  }

  const getToken = computed(() => general.value.token);
  function setToken(newToken: tokenType) {
    general.value.token = { ...newToken };
  }

  const getIsMainError = computed(() => general.value.isMainError);
  function setIsMainError(mainErrorFlag: boolean) {
    general.value.isMainError = mainErrorFlag;
  }

  return {
    getIsApp,
    setIsApp,
    getIsDemo,
    setIsDemo,
    getAppType,
    setAppType,
    getToken,
    setToken,
    getIsMainError,
    setIsMainError,
  };
});
