import { ko } from 'src/i18n/ko/ko';
import {
  $AssessmentGuideDialog,
  $BeforeAssessment,
  $BottomNavigation,
  $CognitiveSkillInfo,
  $HomeHeader,
  $HomeScore,
  $HomeTrainingButton,
  $SettingMenu,
  $SettingTitle,
  $SettingUserAttendance,
  $Onboarding,
  $PersonalTrainingInfo,
  $Reassessment,
  $TrainingButton,
  $TrainingTitle,
  $Withdraw,
  $CustomerCenter,
  $LogoutDialog,
  $AppVersion,
} from 'src/i18n/en/assessmentAndTraining';
import {
  $ErrorBottomDialog,
  $NotFound,
  $SignInErrorDialog,
  $TrainingCategories,
  $TrainingGameTitle,
} from 'src/i18n/en/common';
import {
  $ExitNotice,
  $GameResult,
  $InputWindowPlaceHolder,
  $SemiName,
  $TrainingInfo,
} from 'src/i18n/en/training';
import {
  $Agreement,
  $UserInfoFinish,
  $ButtonText,
  $ScreenFixGuideDialog,
  $ScreenRotateGuide,
  $UserInfoBirth,
  $UserInfoCode,
  $UserInfoEduction,
  $UserInfoGender,
  $UserInfoName,
  $UserInfoPhone,
  $AssessmentInfoButton,
  $NumericInfo,
  $NumericPracticeCorrect,
  $NumericPracticeWrong,
  $NumericFinish,
  $StroopInfo,
  $StroopPracticeCorrect,
  $StroopPracticeWrong,
  $StroopFinish,
  $RememberInfo,
  $RememberExample,
  $RememberPracticeCorrect,
  $RememberTestExample,
  $RememberFinish,
  $MemorizeInfo,
  $MemorizeFinish,
  $TotalFinish,
  $NumericHeaderTitle,
  $StroopHeaderTitle,
  $RememberHeaderTitle,
  $MemorizeSentence,
  $MemorizeTitle,
  $ChooseBiggerTitle,
  $ChooseBigger,
  $UserInfoAgreements,
  $AssessmentExitDialog,
  $RememberCountdown,
  $RememberPracticeWrong,
} from 'src/i18n/en/assessment';

export const en: typeof ko = {
  App: { ...$ErrorBottomDialog, ...$SignInErrorDialog },
  NotFound: { ...$NotFound },

  Entry: { ...$Onboarding },
  BottomNavigation: { ...$BottomNavigation },
  Home: { ...$AssessmentGuideDialog },
  BeforeAssessment: { ...$BeforeAssessment, ...$Reassessment },
  Header: { ...$HomeHeader },
  Score: { ...$HomeScore },
  InfoDialog: { ...$PersonalTrainingInfo, ...$CognitiveSkillInfo },
  TrainingButton: { ...$HomeTrainingButton, ...$TrainingButton },
  Training: { ...$TrainingTitle },
  CustomTraining: {
    ...$TrainingButton,
    ...$TrainingCategories,
    ...$TrainingGameTitle,
  },
  AllTraining: { ...$TrainingCategories, ...$TrainingGameTitle },
  Setting: { ...$SettingTitle, ...$AssessmentGuideDialog, ...$LogoutDialog },
  UserAttendance: { ...$SettingUserAttendance },
  SettingMenu: { ...$SettingMenu },
  Withdraw: { ...$Withdraw },
  CustomerCenter: { ...$CustomerCenter },
  AppVersion: { ...$AppVersion },

  ScreenRotateGuide: { ...$ScreenRotateGuide },
  ScreenFixGuideDialog: { ...$ScreenFixGuideDialog, ...$ButtonText },
  AssessmentExitDialog: { ...$AssessmentExitDialog },
  UserInfo: {
    ...$UserInfoAgreements,
    ...$UserInfoCode,
    ...$UserInfoName,
    ...$UserInfoPhone,
    ...$UserInfoBirth,
    ...$UserInfoGender,
    ...$UserInfoEduction,
    ...$Agreement,
    ...$ButtonText,
  },
  UserInfoInput: {
    ...$UserInfoCode,
    ...$UserInfoName,
    ...$UserInfoPhone,
  },
  UserInfoAgreements: {
    ...$UserInfoAgreements,
  },
  UserInfoBirth: { ...$UserInfoBirth },
  UserInfoGender: { ...$UserInfoGender },
  AssessmentInfo: {
    ...$UserInfoFinish,
    ...$NumericInfo,
    ...$NumericPracticeCorrect,
    ...$NumericPracticeWrong,
    ...$NumericFinish,
    ...$StroopInfo,
    ...$StroopPracticeCorrect,
    ...$StroopPracticeWrong,
    ...$StroopFinish,
    ...$RememberInfo,
    ...$RememberExample,
    ...$RememberPracticeCorrect,
    ...$RememberPracticeWrong,
    ...$RememberTestExample,
    ...$RememberFinish,
    ...$MemorizeInfo,
    ...$MemorizeFinish,
    ...$TotalFinish,
    ...$AssessmentInfoButton,
  },
  NumericStroop: { ...$NumericHeaderTitle, ...$StroopHeaderTitle },
  Remember: { ...$RememberHeaderTitle },
  RememberCountdown: { ...$RememberCountdown },
  Memorize: { ...$MemorizeTitle, ...$ChooseBiggerTitle },
  MemorizeSentence: { ...$MemorizeSentence },
  ChooseBigger: { ...$ChooseBigger },

  TrainingInfo: { ...$TrainingInfo },
  TrainingChat: { ...$ExitNotice },
  ChatDialogs: { ...$ExitNotice },
  TrainingChatHeader: { ...$TrainingGameTitle },
  ChatHeader: { ...$TrainingGameTitle },
  InputWindow: { ...$InputWindowPlaceHolder },
  TrainingChats: { ...$TrainingGameTitle, ...$SemiName },
  ChatMain: { ...$TrainingGameTitle, ...$SemiName },
  ChatGameResult: { ...$GameResult },

  DemoTraining: { ...$TrainingTitle },
};
