import { tokenType } from 'src/types/constType';

export function requestAppleLogin() {
  // request ios apple login
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  )
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'requestSignIn',
    });
}

export function requestGoogleLogin() {
  // request aos google login
  if (window.alzguardAOS && window.alzguardAOS.requestSignIn)
    window.alzguardAOS.requestSignIn();
}

export function requestHaiiLogin(email: string, password: string) {
  // request aos google login
  if (window.alzguardAOS && window.alzguardAOS.requestHaiiSignIn)
    window.alzguardAOS.requestHaiiSignIn(email, password);
}

export function requestATCodeLogin(atCode: string) {
  // request aos google login
  if (window.alzguardAOS && window.alzguardAOS.requestATCodeLogin)
    window.alzguardAOS.requestATCodeLogin(atCode);
}

export function getToken() {
  // android
  if (window.alzguardAOS && window.alzguardAOS.getToken)
    window.alzguardAOS.getToken();

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  )
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'getToken',
    });
}

export function refreshToken(refreshTokenData: tokenType) {
  // android
  if (window.alzguardAOS && window.alzguardAOS.refreshToken)
    window.alzguardAOS.refreshToken(JSON.stringify(refreshTokenData));

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  )
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'refreshToken',
      params: JSON.stringify(refreshTokenData),
    });
}

// 페이지 변경 시 route name 전달
export function routeNameInteraction(routeName: string) {
  // android
  if (window.alzguardAOS && window.alzguardAOS.routeName)
    window.alzguardAOS.routeName(routeName);

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  )
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'routeName',
      params: routeName,
    });
}

export function setNotificationInfo(alertFlag: boolean) {
  // 알림 값 변경을 위한 interface 호출
  // aos
  if (window.alzguardAOS && window.alzguardAOS.setNotification)
    window.alzguardAOS.setNotification(alertFlag);

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  ) {
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'setNotification',
      params: alertFlag,
    });
  }
}

function requestNotificationPermission() {
  // 알림 미허용 팝업에서 설정하기 클릭 시 앱 시스템으로 이동하기 위한 interface 호출
  // aos
  if (window.alzguardAOS && window.alzguardAOS.requestSystemPermission)
    window.alzguardAOS.requestSystemPermission('notification');

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  ) {
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'requestSystemPermission',
      params: 'notification',
    });
  }
}

function requestMicPermission() {
  // android
  if (window.alzguardAOS && window.alzguardAOS.requestSystemPermission)
    window.alzguardAOS.requestSystemPermission('mic');

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  )
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'requestSystemPermission',
      params: 'mic',
    });
}

export function requestSystemPermission(type: string) {
  switch (type) {
    case 'notification':
      requestNotificationPermission();
      break;
    case 'mic':
      requestMicPermission();
      break;
    default:
      break;
  }
}

export function requestMic() {
  // android
  if (window.alzguardAOS && window.alzguardAOS.requestMic)
    window.alzguardAOS.requestMic();

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  )
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'requestMic',
    });
}

export function startAssessment() {
  // android
  if (window.alzguardAOS && window.alzguardAOS.startAssessment) {
    window.alzguardAOS.startAssessment();
  }

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  ) {
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'startAssessment',
    });
  }
}

// 두뇌 검진 종료
export function endAssessment() {
  // android
  if (window.alzguardAOS && window.alzguardAOS.endAssessment)
    window.alzguardAOS.endAssessment();

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  )
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'endAssessment',
    });
}

export function showOpenLicense() {
  // 오픈소스 라이센스 열람을 위한 interface 호출
  // aos
  if (window.alzguardAOS && window.alzguardAOS.onOSSLicenses)
    window.alzguardAOS.onOSSLicenses();

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  ) {
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'onOSSLicenses',
    });
  }
}

export function signout() {
  // android
  if (window.alzguardAOS && window.alzguardAOS.signout)
    window.alzguardAOS.signout();

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  )
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'signout',
    });
}

export function withdraw() {
  // android
  if (window.alzguardAOS && window.alzguardAOS.withdraw)
    window.alzguardAOS.withdraw();

  // ios
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.alzguardIOS &&
    window.webkit.messageHandlers.alzguardIOS.postMessage
  )
    window.webkit.messageHandlers.alzguardIOS.postMessage({
      action: 'withdraw',
    });
}
