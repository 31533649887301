import { computed, Ref, ref } from 'vue';
import axios from 'axios';
import { decode, encode } from 'js-base64';
import { AuthorizeType, InitType } from 'src/composables/server/useHaiiExAuth';
import cookie from 'js-cookie';

const $axios = axios.create({ withCredentials: true });
const initParam: Ref<InitType> = ref({} as InitType);

// base64 Encode
function queryEncode(authorize: any): string {
  return encode(JSON.stringify(authorize));
}

// base64 decode
function queryDecode(value: string): string {
  return decode(value);
}

export default function useDemoExAuth() {
  // serverUrl
  const serverUrl = computed((): string => {
    if (initParam.value.isProduction) return 'haii.io';
    return 'haiidev.co.kr';
  });

  function init(newInitParam: InitType): void {
    initParam.value = newInitParam;
  }

  function signInDemo(): void {
    const authorize: AuthorizeType = {
      serviceName: initParam.value.serviceName,
      signInRedirectURI:
        initParam.value.signInRedirectURI ?? window.location.href,
      lang: initParam.value.lang ?? 'ko',
    };

    const queryBase64 = queryEncode(authorize);

    window.location.href = `https://${serverUrl.value}/auth/signin/web?ex=${queryBase64}`;
  }

  // 로그아웃 메서드
  function signOutDemo(): void {
    cookie.remove('_at', {
      domain:
        process.env.VUE_APP_ENV === 'prod' ? '.haii.io' : '.haiidev.co.kr',
    });
    location.reload();
  }

  // token 을 decoding 하여 Expire 유무 체크
  function isRemainExpire(token: string): boolean {
    const payload = JSON.parse(queryDecode(token.split('.')[1])) as {
      exp: number;
    };

    const now = new Date().getTime();
    return now <= payload.exp * 1000;
  }

  // refresh token을 사용하여 access
  async function resetDemoAccessToken(): Promise<boolean> {
    try {
      const refreshServerUrl = `https://auth.${serverUrl.value}/refresh`;

      const res = await $axios.get(refreshServerUrl);

      return res.data.code === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async function manageDemoToken(): Promise<void> {
    // 토큰 가져오기
    const token = cookie.get('_at');

    // 토큰이 없을 때
    if (!token) return signInDemo();

    //  프론트에서 JWT 토큰을 디코딩하여 access token Expire date 확인
    // 토큰이 있고 exp 가 남았을 경우

    if (isRemainExpire(token)) return;

    // 토큰 있고 exp 가 안남았을 때 -> 토큰  재 발행
    const refreshTokenState = await resetDemoAccessToken();
    // // 정상 재 발행
    if (refreshTokenState) return;
    //
    // //  refresh token 마저 만료 됐으면 로그아웃
    return signOutDemo();
  }

  return {
    init,
    signInDemo,
    signOutDemo,
    isRemainExpire,
    resetDemoAccessToken,
    manageDemoToken,
  };
}
