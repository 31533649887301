import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';
import routes from './routes';
import {
  endAssessment,
  routeNameInteraction,
  startAssessment,
} from 'src/composables/useAppInterface';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    ),
  });

  Router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      routeNameInteraction(String(to.name));

      // 검진 시작
      const fromAt = from.path.includes('/at');
      const toAssessment = to.path.includes('/assessment');
      if (fromAt && toAssessment) startAssessment();

      // 검진 종료
      const fromAssessment = from.path.includes('/assessment');
      const toAt = to.path.includes('/at');
      if (fromAssessment && toAt) endAssessment();

      next();
    }
  );
  return Router;
});
